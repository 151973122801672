import type {DOMAttributes} from '@react-types/shared';

import {CSSStyles} from '../../../types/CSSStyles';
import {Div, H1, H2, H3, H4, H5, H6, Li, P, Span} from '../../html';
import useMediaQuery from '../hooks/useMediaQuery';
import {MediaQuery} from '../hooks/useMediaQueryGetter';
import useContentPalette, {ContentColor} from '../theme/useContentPalette';

import {css} from '@emotion/react';
import {CSSInterpolation} from '@emotion/serialize';
import React from 'react';

export enum Size {
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  LL = 'll',
  XL = 'xl',
  XXL = 'xxl',
  PAGE_TITLE = 'page-title',
}

const FONT_SIZES = {
  [Size.XS]: 12,
  [Size.S]: 14,
  [Size.M]: 16,
  [Size.L]: 18,
  [Size.LL]: 22,
  [Size.XL]: 32,
  [Size.XXL]: 44,
  [Size.PAGE_TITLE]: 64,
};

const FONT_SIZES_SMALL = {
  [Size.XS]: 10,
  [Size.S]: 12,
  [Size.M]: 14,
  [Size.L]: 16,
  [Size.LL]: 20,
  [Size.XL]: 24,
  [Size.XXL]: 28,
  [Size.PAGE_TITLE]: 32,
};

const MARGIN_TOP = {
  [Size.XS]: 12,
  [Size.S]: 16,
  [Size.M]: 24,
  [Size.L]: 32,
  [Size.LL]: 32,
  [Size.XL]: 32,
  [Size.XXL]: 32,
  [Size.PAGE_TITLE]: 32,
};
const MARGIN_TOP_SMALL = {
  [Size.XS]: 6,
  [Size.S]: 8,
  [Size.M]: 12,
  [Size.L]: 16,
  [Size.LL]: 16,
  [Size.XL]: 16,
  [Size.XXL]: 16,
  [Size.PAGE_TITLE]: 16,
};
const LI_MARGIN_TOP = {
  [Size.XS]: 12,
  [Size.S]: 12,
  [Size.M]: 12,
  [Size.L]: 12,
  [Size.LL]: 12,
  [Size.XL]: 12,
  [Size.XXL]: 12,
  [Size.PAGE_TITLE]: 12,
};

const LINE_HEIGHT = {
  [Size.XS]: '24px',
  [Size.S]: '32px',
  [Size.M]: '32px',
  [Size.L]: '36px',
  [Size.LL]: '36px',
  [Size.XL]: '48px',
  [Size.XXL]: '64px',
  [Size.PAGE_TITLE]: '64px',
};
const LINE_HEIGHT_SMALL = {
  [Size.XS]: '12px',
  [Size.S]: '14px',
  [Size.M]: '32px',
  [Size.L]: '36px',
  [Size.LL]: '36px',
  [Size.XL]: '45px',
  [Size.XXL]: '50px',
  [Size.PAGE_TITLE]: '32px',
};

const DEFAULT_VARIANT_MAPPING = {
  body1: P,
  body2: P,
  div: Div,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  inherit: P,
  li: Li,
  span: Span,
  subtitle1: H6,
  subtitle2: H6,
};

const FONT_FAMILIES = {
  GARAMOND: '"adobe-garamond-pro", serif',
  NOTO_SANS_JS: '"Noto Sans JP", serif',
};

export type FontFamily = keyof typeof FONT_FAMILIES;

export type Variant = keyof typeof DEFAULT_VARIANT_MAPPING;

export interface Props extends DOMAttributes {
  readonly children?: React.ReactNode;
  /**
   * @default primary
   */
  readonly color?: ContentColor;
  readonly fontFamily?: FontFamily;
  readonly isDisabled?: boolean;
  readonly rootStyle?: CSSStyles;
  /**
   * @default s
   */
  readonly size?: Size;
  /**
   * @default div
   */
  readonly variant?: Variant;
  readonly variantMapping?: typeof DEFAULT_VARIANT_MAPPING;
  readonly weight?: number;
}

export default function Typography(props: Props): JSX.Element {
  const {
    children,
    color,
    fontFamily,
    isDisabled,
    rootStyle,
    size,
    variant,
    variantMapping = DEFAULT_VARIANT_MAPPING,
    weight,
    ...domProps
  } = props;
  const Component = variantMapping[variant ?? 'div'];
  const palette = useContentPalette(color);
  const colorStyle = isDisabled ? palette.disabled : palette.default;
  const finalSize = size ?? Size.L;
  const marginTop =
    variant === 'li' ? LI_MARGIN_TOP[finalSize] : MARGIN_TOP[finalSize];
  const mobileMarginTop =
    variant === 'li' ? LI_MARGIN_TOP[finalSize] : MARGIN_TOP_SMALL[finalSize];

  const cssOptions: CSSInterpolation = {
    '& + &': {
      marginTop,
    },
    color: colorStyle,
    fontFamily:
      fontFamily == null
        ? FONT_FAMILIES.NOTO_SANS_JS
        : FONT_FAMILIES[fontFamily],
    fontSize: FONT_SIZES[finalSize],
    fontWeight: weight,
    lineHeight: LINE_HEIGHT[finalSize],
    wordBreak: 'normal',

    margin: 0,

    [useMediaQuery(MediaQuery.SmallScreen)]: {
      '& + &': {
        marginTop: mobileMarginTop,
      },
      fontSize: FONT_SIZES_SMALL[finalSize],

      lineHeight: LINE_HEIGHT_SMALL[finalSize],
    },
  };

  return (
    <Component
      css={[css(cssOptions), rootStyle]}
      {...domProps}
    >
      {React.Children.map(children, (child) => {
        if (typeof child === 'string') {
          const splitByBr = child.split('<br>');

          return (
            <>
              {splitByBr.map((part, i) => {
                let newLine = null;
                if (i < splitByBr.length - 1) {
                  newLine = <br />;
                }
                return (
                  <React.Fragment key={i}>
                    {part}
                    {newLine}
                  </React.Fragment>
                );
              })}
            </>
          );
        }
        return child;
      })}
    </Component>
  );
}
